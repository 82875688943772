import { useStorage, StorageSerializers } from '@vueuse/core'
import type { Zone } from '@/components/signup/types'
import { mapZoneToStatus } from '@/utils/fiberStatusMap'
import type { MappedPackage } from '@/components/signup/types'
import type { KioskContact } from '@/types/kiosk'

export function useAddressLocalStorage () {
	const setAddressObject = (address: string, addressId: number, zone: Zone, billables: MappedPackage[], accountFound: boolean) => {
		const status = mapZoneToStatus(zone)

		localStorage.setItem('addressResponse', JSON.stringify({ address, addressId, status, billables, accountFound }))
		return { address, addressId, status, billables, accountFound }
	}
	const cachedAddressResponse = useStorage('addressResponse', null, undefined, { serializer: StorageSerializers.object })

	return {
		setAddressObject,
		cachedAddressResponse
	}
}

export function useLanguageLocalStorage () {
	const setLanguage = (language: string) => {
		localStorage.setItem('language', language)
	}
	const cachedLanguage = useStorage('language', null)

	return {
		setLanguage,
		cachedLanguage
	}
}

export function useKioskLocalStorage () {
	const setContact = (contact: KioskContact) => {
		if (localStorage.getItem('kioskContact')) {
			const currentContacts = JSON.parse(localStorage.getItem('kioskContact') as string)
			const newContact = [ ...currentContacts, contact ]
			localStorage.setItem('kioskContact', JSON.stringify(newContact))
		} else {
			localStorage.setItem('kioskContact', JSON.stringify([ contact ]))
		}
	}

	const getContacts = () => {
		return JSON.parse(localStorage.getItem('kioskContact') as string)
	}

	const clearContacts = () => {
		localStorage.removeItem('kioskContact')
	}
	return {
		setContact,
		getContacts,
		clearContacts
	}
}
