import type { Zone, FiberStatus } from '@/components/signup/types'

export function mapZoneToStatus (zone: Zone): FiberStatus {
	switch (zone) {
	case 'in_service':
		return 'internetAvailable'
	case 'remote':
		return 'internetUnavailable'
	case 'extended':
		return 'underConstruction'
	default:
		return 'internetUnavailable'
	}
}

export function mapStatusToZone (status: FiberStatus): Zone {
	switch (status) {
	case 'internetAvailable':
		return 'in_service'
	case 'internetUnavailable':
		return 'remote'
	case 'underConstruction':
		return 'extended'
	default:
		return 'remote'
	}
}

export default { mapZoneToStatus, mapStatusToZone }
